import React from "react"
import {
  Box,
  Heading,
  Text,
  Container,
  Flex,
  Link as ThemeLink,
} from "theme-ui"
import { useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

// import app components
import BackgroundImage from "../components/BackgroundImage"

const CoomingSoon = () => {
  // const data = useStaticQuery(graphql`
  //   query {
  //     file(relativePath: { eq: "coming_soon.jpg" }) {
  //       childImageSharp {
  //         gatsbyImageData(
  //           width: 200
  //           placeholder: BLURRED
  //           formats: [AUTO, WEBP, AVIF]
  //         )
  //       }
  //     }
  //   }
  // `)
  return (
    <Container
      sx={{
        // "&:before": {
        //   content: "''",
        //   position: "absolute",
        //   zIndex: 10,
        //   inset: "130px 420px 130px 420px",
        //   background:
        //     "linear-gradient(180deg, rgba(3, 15, 26, 0.74) 0%, #030F1A 100%)",
        // },
        height: "100vh",
        width: "100%",
        position: "relative",
      }}
    >
      <Box
        sx={{
          position: ["relative", "absolute"],
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",

          width: ["100%", "80%", "45%"],
          height: ["75%"],
          minHeight: ["600px"],
          "@media screen and (max-width:1124px)": {
            width: ["100%", "80%"],
          },
          background:
            "linear-gradient(180deg, rgba(3, 15, 26, 0.74) 0%, #030F1A 100%)",
          positition: "relative",
          zIndex: "10",
          texAlign: "center",
          mx: "auto",
          py: ["130px", "100px", "130px"],
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          flexWrap: "no-wrap",
          color: "#FFFFFF",
        }}
      >
        <Box mx={"auto"}>
          <StaticImage
            src="../images/PWWW_logo_dark_green-ww_rgb 2.svg"
            alt="A dinosaur"
            placeholder="blurred"
            layout="fixed"
          />
        </Box>
        <Box
          mx={"auto"}
          sx={{
            display: "flex",
            justifyContent: "space-around",
            flexWrap: "wrap",
            pt: "30px",
            textAlign: "center",
          }}
        >
          <Box
            sx={{
              "&:after": {
                content: "''",

                borderRight: "1px solid #74BB23",
                transform: "transform: rotate(90deg)",
                height: "15px",
                alignSelf: "center",
              },
            }}
          >
            <ThemeLink
              sx={{
                padding: ["10px", "15px", "20px"],
                fontSize: ["14px", "18px"],
                color: "#FFFFFF",

                " &:hover": { color: "#00943C" },
              }}
              href="/"
            >
              Paneling
            </ThemeLink>
          </Box>
          <Box
            sx={{
              "&:after": {
                content: "''",

                borderRight: "1px solid #74BB23",
                transform: "transform: rotate(90deg)",
                height: "15px",
                alignSelf: "center",
              },
            }}
          >
            <ThemeLink
              sx={{
                padding: ["10px", "15px", "20px"],
                fontSize: ["14px", "18px"],
                color: "#FFFFFF",

                " &:hover": { color: "#00943C" },
              }}
              href="/"
            >
              Siding
            </ThemeLink>
          </Box>
          <Box
            sx={{
              "&:after": {
                content: "''",

                borderRight: "1px solid #74BB23",
                transform: "transform: rotate(90deg)",
                height: "15px",
                alignSelf: "center",
              },
            }}
          >
            <ThemeLink
              sx={{
                padding: ["10px", "15px", "20px"],
                fontSize: ["14px", "18px"],
                color: "#FFFFFF",

                " &:hover": { color: "#00943C" },
              }}
              href="/"
            >
              Fascia & Trim
            </ThemeLink>
          </Box>
          <Box
            sx={{
              "&:after": {
                content: "''",

                borderRight: "1px solid #74BB23",
                transform: "transform: rotate(90deg)",
                height: "15px",
                alignSelf: "center",
              },
            }}
          >
            <ThemeLink
              sx={{
                padding: ["10px", "15px", "20px"],
                fontSize: ["14px", "18px"],
                color: "#FFFFFF",

                " &:hover": { color: "#00943C" },
              }}
              href="/"
            >
              Dimension
            </ThemeLink>
          </Box>
          <Box>
            <ThemeLink
              sx={{
                padding: ["10px", "15px", "20px"],
                fontSize: ["14px", "18px"],
                color: "#FFFFFF",

                " &:hover": { color: "#00943C" },
              }}
              href="/"
            >
              Engineered
            </ThemeLink>
          </Box>
        </Box>
        <Box py={["50px", "50px", "50px"]} mx={"auto"}>
          <Text as="h2" sx={{ fontSize: ["30px", "36px"], color: "#FFFFFF" }}>
            We’re under construction.
          </Text>
        </Box>
        <Box
          mx={"auto"}
          sx={{
            display: "flex",
            flexDirection: ["column", "row"],
            justifyContent: "space-around",
            color: "#FFFFFF",
            gap: ["10px", "10px", "30px"],
            pb: "12px",
          }}
        >
          <ThemeLink
            color="white"
            sx={{
              fontSize: ["14px", "18px"],
              lineHeight: "26px",
              letterSpacing: "1px",
              " &:hover": { color: "#00943C" },
            }}
            href="tel:6049462910"
          >
            t: 604.946.2910
          </ThemeLink>
          <ThemeLink
            color="white"
            sx={{
              fontSize: ["14px", "18px"],
              lineHeight: "26px",
              letterSpacing: "1px",
              " &:hover": { color: "#00943C" },
            }}
            href="tel:6049462910"
          >
            f: 604.946.2957
          </ThemeLink>
          <ThemeLink
            color="white"
            sx={{
              fontSize: ["14px", "18px"],
              lineHeight: "26px",
              letterSpacing: "1px",
              " &:hover": { color: "#00943C" },
            }}
            href="mailto:6049462910"
          >
            sales@pwww.ca
          </ThemeLink>
        </Box>
        <Box mx={"auto"}>
          <Text sx={{ fontSize: ["14px", "18px"], color: "#FFFFFF" }}>
            7145 Brown Street, Delta, BC V4G 1G5
          </Text>
        </Box>
      </Box>

      <StaticImage
        src="../images/coming_soon.jpg"
        alt="A dinosaur"
        placeholder="blurred"
        layout="fixed"
        style={{
          width: "100%",
          height: "100vh",
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: -10,
        }}
      />

      {/* <Flex
        sx={{
          position: ["none", "absolute"],
          inset: [
            "30px 50px 30px 50px",
            "75px 210px 75px 210px",
            "130px 420px 130px 420px",
          ],

          background:
            "linear-gradient(180deg, rgba(3, 15, 26, 0.74) 0%, #030F1A 100%)",
        }}
      >
        <Box
          color={"white"}
          sx={{
            display: "flex",
            flexDirection: "column",
            heigh: "100%",
            width: "100%",
          }}
        >
          <Box>
            <Text>Paneling</Text>
          </Box>
        </Box>
      </Flex> */}
    </Container>
  )
}

export default CoomingSoon
